/**
 * @generated SignedSource<<986e67f2e106435429631a8313cec1bf>>
 * @relayHash 0e5ebbaf3cdc761d19e4741f1ee6cfa9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8c834a0b9822849a2fe10120e093d7fbe1c47994e5fe9910fb6732007284c7a9

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type projectNavigation_horizontalNavigationQuery$variables = {
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  projectIdOrKey: string;
  useContainerNavigation: boolean;
};
export type projectNavigation_horizontalNavigationQuery$data = {
  readonly jira: {
    readonly " $fragmentSpreads": FragmentRefs<"tabs_businessHorizontalNav_TabsContent">;
  };
};
export type projectNavigation_horizontalNavigationQuery = {
  response: projectNavigation_horizontalNavigationQuery$data;
  variables: projectNavigation_horizontalNavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIdOrKey"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useContainerNavigation"
},
v5 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v6 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "projectIdOrKey",
    "variableName": "projectIdOrKey"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "label"
},
v10 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v11 = {
  "kind": "ScalarField",
  "name": "url"
},
v12 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v13 = {
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "name": "isDefault"
    },
    {
      "kind": "ScalarField",
      "name": "typeKey"
    },
    {
      "kind": "TypeDiscriminator",
      "abstractKey": "__isJiraNavigationItem"
    },
    (v9/*: any*/),
    {
      "kind": "ScalarField",
      "name": "canRemove"
    },
    {
      "kind": "ScalarField",
      "name": "canSetAsDefault"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraAppSection",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v9/*: any*/),
            {
              "concreteType": "JiraAppNavigationItemNestedLink",
              "kind": "LinkedField",
              "name": "links",
              "plural": true,
              "selections": [
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/)
              ]
            }
          ]
        },
        (v11/*: any*/),
        (v10/*: any*/),
        (v12/*: any*/),
        {
          "kind": "ScalarField",
          "name": "appType"
        },
        {
          "kind": "ScalarField",
          "name": "envLabel"
        }
      ],
      "type": "JiraAppNavigationItem"
    }
  ]
},
v14 = [
  {
    "kind": "ScalarField",
    "name": "message"
  },
  {
    "kind": "LinkedField",
    "name": "extensions",
    "plural": true,
    "selections": [
      (v7/*: any*/),
      {
        "kind": "ScalarField",
        "name": "statusCode"
      }
    ]
  }
],
v15 = {
  "concreteType": "QueryError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": (v14/*: any*/)
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "tabs_businessHorizontalNav_TabsContent"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "navigationItems",
                "args": (v6/*: any*/),
                "concreteType": "JiraNavigationItemConnection",
                "kind": "LinkedField",
                "name": "jwmSavedViewsByProject",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraNavigationItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  (v15/*: any*/),
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "alias": "navigationItems",
                "args": (v6/*: any*/),
                "filters": [
                  "cloudId",
                  "projectIdOrKey"
                ],
                "handle": "connection",
                "key": "tabs_jiraNavigation__navigationItems",
                "kind": "LinkedHandle",
                "name": "jwmSavedViewsByProject"
              }
            ]
          },
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "containerNavigationInput"
                  }
                ],
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraContainerNavigationResult"
                  },
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "canEdit"
                      },
                      {
                        "args": [
                          (v5/*: any*/)
                        ],
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraNavigationItemEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              (v7/*: any*/)
                            ]
                          },
                          (v15/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v14/*: any*/),
                    "type": "QueryError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "8c834a0b9822849a2fe10120e093d7fbe1c47994e5fe9910fb6732007284c7a9",
    "metadata": {},
    "name": "projectNavigation_horizontalNavigationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c0418213b2de92dc6932fb62293b398d";

export default node;
