import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { coreProjectLayout } from '@atlassian/jira-core-project-layout';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector';
import {
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	forgeProjectSettingsModuleResource,
	staticProjectSettingsSidebarConnectAddonsNavigationResource,
} from '@atlassian/jira-navigation-apps-resources';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core';
import { createEntry } from '@atlassian/jira-route-entry';
import { type RouteContext, Redirect } from '@atlassian/jira-router';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarAutomationLegacyEnabledResource } from '@atlassian/jira-router-resources-navigation-sidebar-automation';
import { newIssueViewTransitionLockInStatusResource } from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status';
import {
	projectContextResourceWithCache,
	projectContextResource,
} from '@atlassian/jira-router-resources-project-context';
import { fieldSettingsRelayResource } from '@atlassian/jira-router-resources-project-settings-fields';
import { ErrorPagesNotFound } from '@atlassian/jira-router-routes-common';
import {
	projectSettingsCoreDefaultRedirectRoute,
	projectSettingsCoreIssueTypesRoute,
	projectSettingsCoreIssueTypesWorkflowRoute,
	projectSettingsCoreAppsRoute,
	projectSettingsCoreAppsFieldsRoute,
	projectSettingsCoreDetailsRoute,
	projectSettingsCoreAccessRoute,
	projectSettingsCoreAccessRedirectRoute,
	projectSettingsCoreProformaProjectFormsRoute,
	projectSettingsCoreProformaJiraFormBuilderRoute,
	projectSettingsCoreAutomationRoute,
	projectSettingsCoreAutomationV2Route,
	projectSettingsCoreDataClassificationsRoute,
	projectSettingsCoreNotificationsRoute,
	projectSettingsCoreNotificationsIssueLogsRoute,
	projectSettingsCoreNotificationsEmailRoute,
	projectSettingsCoreForgeAppsRoute,
	projectSettingsCoreApprovalsRoute,
	projectSettingsCoreFieldsRoute,
} from '@atlassian/jira-router-routes-project-settings-core-routes';
import {
	ProjectSettingsAccessSkeleton,
	ProjectSettingsDetailsSkeleton,
} from '@atlassian/jira-skeletons';
import { approvalsProjectSettingsEntryPoint } from '@atlassian/jira-spa-apps-business-approvals-project-settings/entrypoint';
import type ForgeProjectSettingsPageType from '@atlassian/jira-spa-apps-forge-project-settings';
import type ConnectAppControlsSettingsType from '@atlassian/jira-spa-apps-project-settings-connect-app-controls';
import type ConnectAppsSettingsType from '@atlassian/jira-spa-apps-project-settings-connect-apps';
import type DataClassificationsSettingsType from '@atlassian/jira-spa-apps-project-settings-data-classifications';
import type DetailsSettingsType from '@atlassian/jira-spa-apps-project-settings-details';
import type FieldSettingsType from '@atlassian/jira-spa-apps-project-settings-fields';
import type IssueTypeSettingsType from '@atlassian/jira-spa-apps-project-settings-issue-type';
import type ProjectSettingsJiraAutomationType from '@atlassian/jira-spa-apps-project-settings-jira-automation';
import type ProjectSettingsNotificationEmailType from '@atlassian/jira-spa-apps-project-settings-notification-email';
import type PeopleSettingsType from '@atlassian/jira-spa-apps-project-settings-people';
import type ProjectSettingsProformaJiraFormBuilderType from '@atlassian/jira-spa-apps-project-settings-proforma-jira-form-builder';
import type ProjectSettingsProformaProjectFormsType from '@atlassian/jira-spa-apps-project-settings-proforma-project-forms';
import type SoftwareNotificationsSettingsType from '@atlassian/jira-spa-apps-project-settings-software-notifications';
import type SoftwareNotificationsIssueLogsType from '@atlassian/jira-spa-apps-project-settings-software-notifications-issue-logs';
import { notificationsIssueLogsEntryPoint } from '@atlassian/jira-spa-apps-project-settings-software-notifications-issue-logs/entrypoint';
import type WorkflowNavigationSettingsType from '@atlassian/jira-spa-apps-project-settings-workflow-navigation';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const approvalsProjectSettingsPageEntryPoint = createPageEntryPoint({
	main: approvalsProjectSettingsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const emailNotificationsLogEntryPointPage = createPageEntryPoint({
	main: notificationsIssueLogsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const getNavigationSidebarProjectResource = () => [
	projectContextResource,
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
];

const getNavigationSidebarProjectSettingsResource = () => [
	newIssueViewTransitionLockInStatusResource,
	navigationSidebarAutomationLegacyEnabledResource,
	projectContextResourceWithCache,
	forgeProjectSettingsModuleResource,
	staticProjectSettingsSidebarConnectAddonsNavigationResource,
];

const LazyIssueTypeSettings = lazyForPaint<typeof IssueTypeSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-issue-type", jiraSpaEntry: "async-project-settings-issue-type" */ '@atlassian/jira-spa-apps-project-settings-issue-type'
		),
	),
);

export const IssueTypePage = ({ match }: RouteContext) => {
	const { issueTypeId } = match.params;
	// unique pageId need to be set so that ApdexProvider would properly reset all the performance marks when switching between issue types
	const pageId = `project-settings-issue-types-${String(issueTypeId)}`;
	return <LazyPage Page={LazyIssueTypeSettings} pageId={pageId} shouldShowSpinner={false} />;
};

const LazyFieldsSettings = lazyForPaint<typeof FieldSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-fields", jiraSpaEntry: "async-project-settings-fields" */ '@atlassian/jira-spa-apps-project-settings-fields'
		),
	),
);

export const FieldsPage = () => (
	<LazyPage Page={LazyFieldsSettings} pageId="project-settings-fields" shouldShowSpinner />
);

const LazyWorkflowNavigationSettings = lazyForPaint<typeof WorkflowNavigationSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-workflow-navigation", jiraSpaEntry: "async-project-settings-workflow-navigation" */ '@atlassian/jira-spa-apps-project-settings-workflow-navigation'
		),
	),
);

export const WorkflowNavigationPage = () => (
	<LazyPage
		Page={LazyWorkflowNavigationSettings}
		pageId="project-settings-workflow-navigation"
		shouldShowSpinner={false}
	/>
);

const LazyConnectAppsSettings = lazyForPaint<typeof ConnectAppsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-connect-apps", jiraSpaEntry: "async-project-settings-connect-apps" */ '@atlassian/jira-spa-apps-project-settings-connect-apps'
		),
	),
);

export const ConnectAppsPage = () => (
	<LazyPage
		Page={LazyConnectAppsSettings}
		pageId="project-settings-connect-apps"
		shouldShowSpinner={false}
	/>
);

const LazyConnectAppControlsSettings = lazyForPaint<typeof ConnectAppControlsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-connect-app-controls", jiraSpaEntry: "async-project-settings-connect-app-controls" */ '@atlassian/jira-spa-apps-project-settings-connect-app-controls'
		),
	),
);

export const ConnectAppControlsPage = () => (
	<LazyPage
		Page={LazyConnectAppControlsSettings}
		pageId="project-settings-connect-app-controls"
		shouldShowSpinner={false}
	/>
);

const LazyDetailsSettings = lazyForPaint<typeof DetailsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-details", jiraSpaEntry: "async-project-settings-details" */ '@atlassian/jira-spa-apps-project-settings-details'
		),
	),
);

export const Details = () => (
	<LazyPage
		Page={LazyDetailsSettings}
		pageId="project-settings-details"
		shouldShowSpinner={false}
	/>
);

const LazyPeopleSettings = lazyForPaint<typeof PeopleSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-people", jiraSpaEntry: "async-project-settings-people" */ '@atlassian/jira-spa-apps-project-settings-people'
		),
	),
);

export const Access = () => (
	<LazyPage Page={LazyPeopleSettings} pageId="project-settings-people" shouldShowSpinner={false} />
);

const LazyProjectSettingsProformaProjectForms = lazyForPaint<
	typeof ProjectSettingsProformaProjectFormsType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-proforma-project-forms", jiraSpaEntry: "async-project-settings-proforma-project-forms" */ '@atlassian/jira-spa-apps-project-settings-proforma-project-forms'
		),
);

export const ProformaProjectForms = () => (
	<LazyPage
		Page={LazyProjectSettingsProformaProjectForms}
		pageId="project-settings-proforma-project-forms"
		shouldShowSpinner
	/>
);

const LazyProjectSettingsProformaJiraFormBuilder = lazyForPaint<
	typeof ProjectSettingsProformaJiraFormBuilderType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-proforma-jira-form-builder", jiraSpaEntry: "async-project-settings-proforma-jira-form-builder" */ '@atlassian/jira-spa-apps-project-settings-proforma-jira-form-builder'
		),
);

export const ProformaJiraFormBuilder = () => (
	<LazyPage
		Page={LazyProjectSettingsProformaJiraFormBuilder}
		pageId="project-settings-proforma-jira-form-builder"
		shouldShowSpinner
	/>
);

const LazyProjectSettingsJiraAutomation = lazyForPaint<typeof ProjectSettingsJiraAutomationType>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-jira-automation", jiraSpaEntry: "async-project-settings-jira-automation" */ '@atlassian/jira-spa-apps-project-settings-jira-automation'
		),
);

export const Automation = () => (
	<LazyPage
		Page={LazyProjectSettingsJiraAutomation}
		pageId="project-settings-jira-automation"
		shouldShowSpinner
	/>
);

const LazyDataClassificationsSettings = lazyForPaint<typeof DataClassificationsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-data-classifications", jiraSpaEntry: "async-project-settings-data-classifications" */ '@atlassian/jira-spa-apps-project-settings-data-classifications'
		),
	),
);

export const DataClassifications = () => (
	<LazyPage
		Page={LazyDataClassificationsSettings}
		pageId="project-settings-data-classifications"
		shouldShowSpinner={false}
	/>
);

const LazyNotificationsSettings = lazyForPaint<typeof SoftwareNotificationsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-core-notifications", jiraSpaEntry: "async-project-settings-software-notifications" */ '@atlassian/jira-spa-apps-project-settings-software-notifications'
		),
	),
);

const LazyNotificationsIssueLogs = lazyForPaint<typeof SoftwareNotificationsIssueLogsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-core-notifications-issue-logs", jiraSpaEntry: "async-project-settings-software-notifications-issue-logs" */ '@atlassian/jira-spa-apps-project-settings-software-notifications-issue-logs'
		),
	),
);

export const Notifications = () => (
	<LazyPage
		Page={LazyNotificationsSettings}
		pageId="project-settings-software-notifications"
		shouldShowSpinner={false}
	/>
);

export const NotificationsIssueLogs = () => (
	<LazyPage
		Page={LazyNotificationsIssueLogs}
		pageId="project-settings-software-notifications-issue-logs"
		shouldShowSpinner={false}
	/>
);

const LazyProjectSettingsNotificationEmail = lazyForPaint<
	typeof ProjectSettingsNotificationEmailType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-notification-email", jiraSpaEntry: "async-project-settings-notification-email" */ '@atlassian/jira-spa-apps-project-settings-notification-email'
		),
	),
);

export const EmailNotifications = () => (
	<LazyPage
		Page={LazyProjectSettingsNotificationEmail}
		pageId="project-settings-email-notifications"
		shouldShowSpinner
	/>
);

const LazyForgeProjectSettingsPage = lazyForPaint<typeof ForgeProjectSettingsPageType>(
	() =>
		import(
			/* webpackChunkName: "async-forge-spa-project-settings", jiraSpaEntry: "async-forge-spa-project-settings" */ '@atlassian/jira-spa-apps-forge-project-settings'
		),
);

export const ForgeProjectSettingsPage = () => (
	<LazyPage
		Page={LazyForgeProjectSettingsPage}
		pageId="forge-spa-project-settings"
		shouldShowSpinner
	/>
);

export const projectSettingsCoreDefaultRedirectRouteEntry = createEntry(
	projectSettingsCoreDefaultRedirectRoute,
	{
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: ({ match }: RouteContext) => (
			<Redirect to={`/jira/core/projects/${String(match.params.projectKey)}/settings/details`} />
		),
		forPaint: [LazyAtlassianNavigation],
	},
);

export const projectSettingsCoreIssueTypesRouteEntry = createEntry(
	projectSettingsCoreIssueTypesRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: IssueTypePage,
		forPaint: [LazyAtlassianNavigation, LazyIssueTypeSettings],
	},
);

export const projectSettingsCoreFieldsRouteEntry = createEntry(projectSettingsCoreFieldsRoute, {
	ufoName: 'project-settings.fields.jwm-tmp',
	exact: true,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarCore,
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	},
	layout: coreProjectLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
		...getBusinessProjectResources(),
		fieldSettingsRelayResource,
	],
	component: componentWithFG('project_fields_page_for_tmp_-_mvp', FieldsPage, ErrorPagesNotFound),
	forPaint: [LazyAtlassianNavigation, LazyFieldsSettings],
});

export const projectSettingsCoreIssueTypesWorkflowRouteEntry = createEntry(
	projectSettingsCoreIssueTypesWorkflowRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: WorkflowNavigationPage,
		forPaint: [LazyAtlassianNavigation, LazyWorkflowNavigationSettings],
	},
);

export const projectSettingsCoreAppsRouteEntry = createEntry(projectSettingsCoreAppsRoute, {
	exact: true,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarCore,
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	},
	layout: coreProjectLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
		...getBusinessProjectResources(),
	],
	component: ConnectAppsPage,
	forPaint: [LazyAtlassianNavigation, LazyConnectAppsSettings],
});

export const projectSettingsCoreAppsFieldsRouteEntry = createEntry(
	projectSettingsCoreAppsFieldsRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: ConnectAppControlsPage,
		forPaint: [LazyAtlassianNavigation, LazyConnectAppControlsSettings],
	},
);

export const projectSettingsCoreDetailsRouteEntry = createEntry(projectSettingsCoreDetailsRoute, {
	exact: true,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarCore,
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	},
	layout: coreProjectLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
		...getBusinessProjectResources(),
	],
	component: Details,
	skeleton: ProjectSettingsDetailsSkeleton,
	forPaint: [LazyAtlassianNavigation, LazyDetailsSettings],
});

export const projectSettingsCoreAccessRouteEntry = createEntry(projectSettingsCoreAccessRoute, {
	exact: true,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarCore,
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	},
	layout: coreProjectLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
		...getBusinessProjectResources(),
	],
	component: Access,
	skeleton: ProjectSettingsAccessSkeleton,
	forPaint: [LazyAtlassianNavigation, LazyPeopleSettings],
	ufoName: 'project-settings.access',
});

export const projectSettingsCoreAccessRedirectRouteEntry = createEntry(
	projectSettingsCoreAccessRedirectRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: ({ match }: RouteContext) => (
			<Redirect
				to={`/jira/core/projects/${String(match.params.projectKey)}/settings/access`}
				push={false}
			/>
		),
		forPaint: [LazyAtlassianNavigation, LazyPeopleSettings],
	},
);

export const projectSettingsCoreProformaProjectFormsRouteEntry = createEntry(
	projectSettingsCoreProformaProjectFormsRoute,
	{
		exact: true,
		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: ProformaProjectForms,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaProjectForms],
		perfMetricKey: 'forms-settings',
	},
);

export const projectSettingsCoreProformaJiraFormBuilderRouteEntry = createEntry(
	projectSettingsCoreProformaJiraFormBuilderRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: ProformaJiraFormBuilder,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaJiraFormBuilder],
		perfMetricKey: 'form-builder',
	},
);

export const projectSettingsCoreAutomationRouteEntry = createEntry(
	projectSettingsCoreAutomationRoute,
	{
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: Automation,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsJiraAutomation],
		ufoName: 'project-settings-automation-core',
	},
);

export const projectSettingsCoreAutomationV2RouteEntry = createEntry(
	projectSettingsCoreAutomationV2Route,
	{
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: Automation,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsJiraAutomation],
	},
);

export const projectSettingsCoreDataClassificationsRouteEntry = createEntry(
	projectSettingsCoreDataClassificationsRoute,
	{
		exact: true,
		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: DataClassifications,
		skeleton: ProjectSettingsDetailsSkeleton,
		forPaint: [LazyAtlassianNavigation],
	},
);

export const projectSettingsCoreNotificationsRouteEntry = createEntry(
	projectSettingsCoreNotificationsRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: Notifications,
		forPaint: [LazyAtlassianNavigation, LazyNotificationsSettings],
	},
);

export const projectSettingsCoreNotificationsIssueLogsRouteEntry = createEntry(
	projectSettingsCoreNotificationsIssueLogsRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: NotificationsIssueLogs,
		forPaint: [LazyAtlassianNavigation, LazyNotificationsIssueLogs],
		entryPoint() {
			return ff('jlovep-1857-enable-project-email-audit-pages-for-business-projects_tz5uu')
				? emailNotificationsLogEntryPointPage
				: undefined;
		},
	},
);

export const projectSettingsCoreNotificationsEmailRouteEntry = createEntry(
	projectSettingsCoreNotificationsEmailRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: EmailNotifications,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsNotificationEmail],
	},
);

export const projectSettingsCoreForgeAppsRouteEntry = createEntry(
	projectSettingsCoreForgeAppsRoute,
	{
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		component: ForgeProjectSettingsPage,
		forPaint: [LazyAtlassianNavigation, LazyForgeProjectSettingsPage],
	},
);

export const projectSettingsCoreApprovalsRouteEntry = createEntry(
	projectSettingsCoreApprovalsRoute,
	{
		exact: true,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
		],
		entryPoint() {
			return approvalsProjectSettingsPageEntryPoint;
		},
	},
);

export const projectSettingsCoreRouteEntries = [
	projectSettingsCoreIssueTypesRouteEntry,
	projectSettingsCoreFieldsRouteEntry,
	projectSettingsCoreIssueTypesWorkflowRouteEntry,
	projectSettingsCoreAppsFieldsRouteEntry,
	projectSettingsCoreAppsRouteEntry,
	projectSettingsCoreDetailsRouteEntry,
	projectSettingsCoreAccessRouteEntry,
	projectSettingsCoreAccessRedirectRouteEntry,
	projectSettingsCoreProformaProjectFormsRouteEntry,
	projectSettingsCoreProformaJiraFormBuilderRouteEntry,
	projectSettingsCoreAutomationV2RouteEntry,
	projectSettingsCoreDataClassificationsRouteEntry,
	projectSettingsCoreAutomationRouteEntry,
	projectSettingsCoreNotificationsRouteEntry,
	projectSettingsCoreNotificationsIssueLogsRouteEntry,
	projectSettingsCoreNotificationsEmailRouteEntry,
	projectSettingsCoreForgeAppsRouteEntry,
	projectSettingsCoreApprovalsRouteEntry,
	projectSettingsCoreDefaultRedirectRouteEntry,
];
