import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { getResourceStore } from '@atlassian/jira-router';

/**
 * Called when project create is initiated. Accesses the react router resource store
 * and removes any existing data that is keyed with the new project key, as there will
 * sometimes be stale data if a project with the same key was recently deleted.
 * @param projectKey - The key of the project we will clear existing resources for
 */
export const clearResourcesAssociatedWithProjectKey = (projectKey: string) => {
	try {
		const { storeState } = getResourceStore();
		const prevState = storeState.getState();

		storeState.setState({
			...prevState,
			data: Object.fromEntries(
				Object.entries(prevState?.data).map(([resourceType, value]) => {
					// This will not clear resources that are keyed with project key as a subset of the key,
					// e.g. `projectKey-${projectKey}` or similar, so those resources may still have stale data.
					const { [projectKey]: _, ...rest } = value;
					return [resourceType, rest];
				}),
			),
		});
	} catch (e) {
		fireErrorAnalytics({
			meta: {
				id: 'clearResourcesAssociatedWithProjectKey',
				packageName: 'jiraProjectCreateDrawerContext',
				teamName: 'helix',
			},
			sendToPrivacyUnsafeSplunk: true,
			error:
				e instanceof Error
					? e
					: new Error('Error occured in clearResourcesAssociatedWithProjectKey'),
		});
	}
};
