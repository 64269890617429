import {
	ROUTE_NAMES_BROWSE_PROJECT_REDIRECT,
	ROUTE_NAMES_LEGACY_ALL_REPORTS,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SOFTWARE_LIST_CLASSIC,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SERVICEDESK_BOARDS,
	ROUTE_NAMES_SERVICEDESK_ISSUE,
	ROUTE_NAMES_SERVICEDESK_ISSUES,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/jira-router';
import { ENTITY_ID, MENU_ID } from '../../../constants';
import type { SelectedPath } from '../../../types';

const routes = new Set<string>([
	ROUTE_NAMES_BROWSE_PROJECT_REDIRECT,
	ROUTE_NAMES_LEGACY_ALL_REPORTS,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,

	// JSW
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SOFTWARE_LIST_CLASSIC,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	// TODO: BLU-3620: continue from JSW TMP Issues menu

	// JSM
	ROUTE_NAMES_SERVICEDESK_BOARDS,
	ROUTE_NAMES_SERVICEDESK_ISSUE,
	ROUTE_NAMES_SERVICEDESK_ISSUES,
	// TODO: continue

	// JWM
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD,
	// TODO: add more routes...
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match } = routeContext;
	const path: SelectedPath = [MENU_ID.PROJECTS];

	switch (routeName) {
		case ROUTE_NAMES_PROJECTS_DIRECTORY:
			path.push(MENU_ID.PROJECTS_VIEW_ALL);
			break;

		// General / Core
		// ROUTE_NAMES_BROWSE_PROJECT_REDIRECT
		// ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR
		// ROUTE_NAMES_LEGACY_ALL_REPORTS
		// ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE
		// JSM
		// ROUTE_NAMES_SERVICEDESK_BOARDS
		// ROUTE_NAMES_SERVICEDESK_ISSUE
		// ROUTE_NAMES_SERVICEDESK_ISSUES
		// JSW
		// ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC
		// ROUTE_NAMES_RAPIDBOARD_BOARD
		// ROUTE_NAMES_RAPIDBOARD_REPORT,
		// ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC
		// ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC
		// ROUTE_NAMES_SOFTWARE_LIST_CLASSIC
		// JWM
		// ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD
		default: {
			const { projectKey, boardId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			boardId && path.push(ENTITY_ID.BOARD(boardId));
		}
	}

	return path;
}

export const projectConverter = { routes, convert };
