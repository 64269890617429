import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { getIsNewValueRequested } from '../../common/utils/get-feature-flag-value';
import { getPermalinkId, COMMENTS } from '../../common/utils/permalinks';
import {
	GIRA_AGG_MIGRATION_ISARCHIVED_FLAG,
	isParentInIssueViewEnabled,
} from '../../feature-flags';

export const getMainIssueViewAggQueryFeatureFlagVariables = () => ({
	issueViewRelayCascadingSelectFlag: ff(
		'issue.details.relay-cascading-select-field-issue-view-layout-integration',
	),

	giraAGGMigrationIsArchivedFlag: getIsNewValueRequested(GIRA_AGG_MIGRATION_ISARCHIVED_FLAG),
	parentInIssueViewFlag: isParentInIssueViewEnabled(),
	isCompassComponentsEnabled: ff('compass-components-in-jira-components_xw42u', false),
	giraAGGMigrationAttachmentsFlag: ff('issue.details.gira-agg-migration-attachments_ecebe'),

	isRelayReleaseVersionFieldIssueViewIntegrationEnabled: ff(
		'issue.details.relay-release-version-field-issue-view-integration',
	),

	issueViewRelayGoalsFlag: ff('hrz-716-integrate-goal-picker-into-issue-view', false),

	giraAGGMigrationCommentsFlag:
		ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9', false) ||
		ff('issue-jiv-14530-comments-consistency-check_x716d', false),

	isCMDBAttributeIdRemoved: ff('assets-rearch-deprecate-attribute-value-id_ykukg', false),
	jsmSentimentCustomFieldFlag: ff('sentiment-custom-field', false),
	isJPDPanelEnabled: ff('polaris.jpd-panel-in-issue-view', false),
	...getCommentQueries(),
	issueViewRelayPriorityFieldFlag: ff('relay-migration-issue-fields-priority_ri9vi', false),
	issueViewRelayAssigneeFieldFlag: ff('relay-migration-issue-fields-assignee-ld', false),
	issueViewRelayNumberFieldFlag: ff('relay-migration-issue-fields-number_q0em4', false),
	issueViewRelaySprintFieldFlag: ff('relay-migration-issue-fields-sprint_jozpu', false),
	issueViewRelayAttachmentsFlag: ff('relay-migration-issue-view-attachments', false),

	issueViewRelaySingleLineTextFieldFlag: ff(
		'relay-migration-issue-fields-single-line-text_in6w6',
		false,
	),

	issueViewRelayDateFieldFlag: ff('relay-migration-issue-fields-date_ptj72', false),
	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent: ff(
		'relay-migration-issue-fields-parent_5p1ac',
		false,
	),
	issueViewRelayProjectFieldFlag: ff('relay-migration-issue-fields-project-select_w0xem', false),
	issueViewRelayDateTimeFieldFlag: ff('relay-migration-issue-fields-date-time_eaqd2', false),
	isJPDDeliveryLinkTypeFilteringEnabled: ff(
		'polaris.jpd-panel-filter-by-delivery-link-type',
		false,
	),
});

export const getCommentQueries = () => ({
	commentTargetId:
		ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9') ||
		ff('issue-jiv-14530-comments-consistency-check_x716d')
			? getPermalinkId(COMMENTS) || null
			: null,
});
