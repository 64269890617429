import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
	getNavigationSidebarProjectSettingsResource,
} from '@atlassian/jira-router-resources-navigation';
import { classicProjectSettingsIssueLayoutRoute } from '@atlassian/jira-router-routes-classic-project-settings-issue-layout-routes';
import IssueTypePage, { LazyIssueTypeSettings } from './ui/spa/project-settings/issue-type';

export const classicProjectSettingsIssueLayoutRouteEntry = createEntry(
	classicProjectSettingsIssueLayoutRoute,
	{
		ufoName: 'project-settings.issue-type.jsw-cmp',
		component: IssueTypePage,
		layout: genericProjectLayout,

		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

		resources: [
			...getBusinessProjectResources(),
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyIssueTypeSettings],
	},
);
