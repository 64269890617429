import type { ComponentType } from 'react';
import type { PopupProps } from '@atlaskit/popup';
import { CONFIGURABLE_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants';
import type { L1MenuItemsQuery$data } from '@atlassian/jira-relay/src/__generated__/L1MenuItemsQuery.graphql';

export type AsyncAction = () => Promise<void>;

export const OPERATONS_MENU_ID = {
	OPERATIONS_ALERT_SAVED_SEARCH_SHARED_WITH_ME:
		'jira.sidebar.operations.alert.savedSearches.sharedWithMe',
	OPERATIONS_ALERT_SAVED_SEARCH_CREATED_BY_ME:
		'jira.sidebar.operations.alert.savedSearches.createdByMe',
} as const;

export type ConfigurableMenuIdKey = keyof typeof CONFIGURABLE_MENU_ID;
export type ConfigurableMenuId = (typeof CONFIGURABLE_MENU_ID)[ConfigurableMenuIdKey];

export type JiraConfigurableNavigationItem = {
	readonly menuId: string;
	readonly isVisible: boolean;
};

export const FILTERABLE_MENU_ID = {
	...CONFIGURABLE_MENU_ID,
	BOARDS: 'jira.sidebar.boards',
	QUEUES: 'jira.sidebar.queues',
	ALL: 'jira.sidebar.all',
} as const;

export type FilterableMenuIdKey = keyof typeof FILTERABLE_MENU_ID;
export type FilterableMenuId = (typeof FILTERABLE_MENU_ID)[FilterableMenuIdKey];

export type IndentProps = {
	indentLevel?: number;
};

export const L1_MENU_ID = {
	YOUR_WORK: 'jira.sidebar.yourWork',
	...CONFIGURABLE_MENU_ID,
	...OPERATONS_MENU_ID,
} as const;

export type MenuIdKey = keyof typeof L1_MENU_ID;
export type MenuId = (typeof L1_MENU_ID)[MenuIdKey];

// A nav menu button can appear in a flyout and may need access to its `onClose`.
export type NavMenuButtonProps = OnCloseProps;
export type NavMenuButton = ComponentType<NavMenuButtonProps>;

// A collection of nav menu buttons can appear in a flyout and may need access to its `onClose`.
export type NavMenuButtonsProps = OnCloseProps;
export type NavMenuButtons = ComponentType<NavMenuButtonsProps>;

// A nav submenu can appear in a flyout and may need access to its `onClose`.
export type NavSubmenuFooterProps = OnCloseProps;
export type NavSubmenuFooter = ComponentType<NavSubmenuFooterProps>;
export type NavSubmenuHeaderProps = OnCloseProps;
export type NavSubmenuHeader = ComponentType<NavSubmenuHeaderProps>;
export type NavSubmenuMenuItemsProps = OnCloseProps;
export type NavSubmenuMenuItems = ComponentType<NavSubmenuMenuItemsProps>;

export type OnClose = NonNullable<PopupProps['onClose']>;
export type OnCloseProps = { onClose?: OnClose };

// This is a temporary type until we know the response types from our APIs
// This is used for rendering the recent and starred flyout menus
export type NavigationType = {
	title: string;
	id: FilterableMenuId;
};

// This is a temporary type used for filtering recent and starred results
export type NavigationFilters = {
	name: string;
	id: FilterableMenuId;
};

export type Nav4RootQueryRef = L1MenuItemsQuery$data;
export type Nav4RootQueryRefProps = { queryReference: Nav4RootQueryRef };
export type ComponentWithNav4RootQueryRef = ComponentType<Nav4RootQueryRefProps>;
