import React from 'react';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants';
import {
	ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE,
	ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	ROUTE_NAMES_POLARIS_IDEAS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { fg } from '@atlassian/jira-feature-gating';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common';
import {
	NavigateBackProvider,
	useNavigateBack,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-back-button';
import {
	AsyncBoardSettingsSidebar,
	BOARD_SETTINGS_ROUTE_NAMES,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-board-settings';
import AsyncBusinessCompanyManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-business-company-managed-project-settings';
import AsyncBusinessTeamManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-business-team-managed-project-settings';
import { SidebarContainer } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-container';
import {
	AsyncGlobalAppsSettingsSidebar,
	useGlobalAppsSettingsConnectItemActive,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-apps-settings';
import { AsyncGlobalIssuesSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-issues-settings';
import { AsyncGlobalProductsSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-products-settings';
import { AsyncGlobalProjectsSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-projects-settings';
import {
	AsyncGlobalSystemSettingsSidebar,
	useGlobalSystemSettingsConnectItemActive,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-global-settings-system-settings';
import AsyncHelpCenterSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-help-center-settings';
import { AsyncJsmCmpProjectSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-jsm-cmp-project-settings';
import { AsyncJsmTmpProjectSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-jsm-tmp-project-settings';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-personal-settings';
import { AsyncPlanSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-plan-settings';
import { Skeleton as ProjectSettingsSkeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-common/src/common/ui/skeleton';
import { useIsConnectAppActive as useIsProjectSettingsConnectAppActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-common/src/controllers/is-connect-app-active';
import { AsyncJpdProjectSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-jpd-settings';
import AsyncSidebarContent from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebar-content/src/async';
import AsyncSoftwareCompanyManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-software-company-managed-project-settings';
import AsyncSoftwareTeamManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-software-team-managed-project-settings';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useRouter } from '@atlassian/jira-router';
import { BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants';

const ProjectSettingsSidebarSelector = () => {
	const { data: project, loading, error } = useProjectContext();

	if (loading) {
		return (
			<SidebarContainer>
				<ProjectSettingsSkeleton />
			</SidebarContainer>
		);
	}

	if (project?.projectType === CORE_PROJECT) {
		return project.simplified ? (
			<AsyncBusinessTeamManagedProjectSettingsSidebar project={project} />
		) : (
			<AsyncBusinessCompanyManagedProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === SOFTWARE_PROJECT) {
		return project.simplified ? (
			<AsyncSoftwareTeamManagedProjectSettingsSidebar project={project} />
		) : (
			<AsyncSoftwareCompanyManagedProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === SERVICE_DESK_PROJECT) {
		return project.simplified ? (
			<AsyncJsmTmpProjectSettingsSidebar project={project} />
		) : (
			<AsyncJsmCmpProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === PRODUCT_DISCOVERY_PROJECT) {
		return <AsyncJpdProjectSettingsSidebar project={project} />;
	}

	if (project?.projectType === CUSTOMER_SERVICE_PROJECT) {
		return project.simplified ? (
			<AsyncJsmTmpProjectSettingsSidebar project={project} />
		) : (
			<AsyncJsmCmpProjectSettingsSidebar project={project} />
		);
	}

	if (error) {
		throw error;
	}

	throw new Error(
		`No project settings sidebar found for the project type: ${project?.projectType}`,
	);
};

const InternalSidebarSelector = () => {
	const [
		{
			match: { params },
			route,
			location,
			query,
		},
	] = useRouter();
	const { saveCurrentRoute } = useNavigateBack();
	const { isActive: isGlobalAppsSettingsConnectItemActive } =
		useGlobalAppsSettingsConnectItemActive();
	const { isActive: isGlobalSystemSettingsConnectItemActive } =
		useGlobalSystemSettingsConnectItemActive();
	const { isActive: isProjectSettingsConnectAppActive } = useIsProjectSettingsConnectAppActive();

	switch (route.navigation?.sidebarId) {
		case SIDEBAR_ID.PLAN_SETTINGS:
			return <AsyncPlanSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_APPS_SETTINGS:
			if (!location.pathname.includes('/discover')) {
				return <AsyncGlobalAppsSettingsSidebar />;
			}
			break;
		case SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS:
			return <AsyncGlobalProjectsSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS:
			return <AsyncGlobalIssuesSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS:
			return <AsyncGlobalProductsSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS:
			return <AsyncGlobalSystemSettingsSidebar />;
		case SIDEBAR_ID.PERSONAL_SETTINGS:
			return <AsyncPersonalSettingsSidebar />;
		case SIDEBAR_ID.HELP_CENTER_SETTINGS:
			return <AsyncHelpCenterSettingsSidebar />;
		case SIDEBAR_ID.PROJECT_SETTINGS:
			return <ProjectSettingsSidebarSelector />;
		default:
			break;
	}

	// Project settings sidebars
	if (
		route.group === ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE ||
		route.group === ROUTE_GROUPS_PROJECT_SETTINGS_CORE ||
		route.group === ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK ||
		(route.name === ROUTE_NAMES_POLARIS_IDEAS && params.section === 'settings') ||
		isProjectSettingsConnectAppActive
	) {
		return <ProjectSettingsSidebarSelector />;
	}

	// Board settings sidebar
	if (
		BOARD_SETTINGS_ROUTE_NAMES.has(route.name) &&
		BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(query.config) &&
		fg('jira_nav4_big_board_settings_visual_refresh_m1')
	) {
		return <AsyncBoardSettingsSidebar />;
	}

	// Global settings sidebars
	if (isGlobalAppsSettingsConnectItemActive) {
		return <AsyncGlobalAppsSettingsSidebar />;
	}
	if (isGlobalSystemSettingsConnectItemActive) {
		return <AsyncGlobalSystemSettingsSidebar />;
	}

	// the route to navigate back to will be whichever route was last displayed
	// with the default sidebar on
	saveCurrentRoute();
	return <AsyncSidebarContent />;
};

export const SidebarSelector = () => (
	<NavigateBackProvider>
		<InternalSidebarSelector />
	</NavigateBackProvider>
);
