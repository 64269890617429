/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, useMemo, useRef } from 'react';

import { cssMap, jsx } from '@compiled/react';

import { PanelSplitterContext, type PanelSplitterContextType } from './context';

const styles = cssMap({
	panelChildrenWrapper: {
		overflow: 'auto',
		height: '100%',
	},
});

export type PanelSplitterProviderProps = Omit<
	PanelSplitterContextType,
	'portalRef' | 'position'
> & {
	children: React.ReactNode;
	position?: 'start' | 'end';
};

/**
 * Provides the context required for the panel splitter to work within a page layout slot. Should be used in the page layout slot components, e.g. SideNav, Aside etc, as opposed to products.
 */
export const PanelSplitterProvider = ({
	panelWidth,
	onCompleteResize,
	resizeBounds,
	resizingCssVar,
	onClick,
	panelRef,
	position = 'end',
	children,
}: PanelSplitterProviderProps) => {
	const portalRef = useRef<HTMLDivElement | null>(null);

	const context: PanelSplitterContextType = useMemo(
		() => ({
			panelWidth,
			onCompleteResize,
			resizeBounds,
			portalRef,
			resizingCssVar,
			onClick,
			position,
			panelRef,
		}),
		[onClick, onCompleteResize, panelRef, panelWidth, position, resizeBounds, resizingCssVar],
	);

	return (
		<Fragment>
			<PanelSplitterContext.Provider value={context}>
				<div css={styles.panelChildrenWrapper}>{children}</div>
			</PanelSplitterContext.Provider>
			{/**
			 * Portal target for rendering the PanelSplitter.
			 * Used to ensure the PanelSplitter can overflow the side nav container, without causing it to stretch or scroll.
			 */}
			<div ref={portalRef} />
		</Fragment>
	);
};
