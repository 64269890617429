/**
 * @generated SignedSource<<c3fbeaf60d9e03c442dd16e80e85bf06>>
 * @relayHash ed05f87a592d7fcb72c8a14b0ed1366b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 590eae2067c729a0d8d3273cdad663b0ffdd5a069cf6ddd7dc4c6d640bc7736a

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainIssueAggQuery } from './mainIssueAggQuery.graphql';

import issueViewRelayParentFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent';

const node: PreloadableConcreteRequest<mainIssueAggQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "590eae2067c729a0d8d3273cdad663b0ffdd5a069cf6ddd7dc4c6d640bc7736a",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "issueByKey",
            "attachments"
          ]
        }
      ]
    },
    "name": "mainIssueAggQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider
    }
  }
};

export default node;
