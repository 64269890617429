import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { ff } from '../get-feature-flag-value';

const visualRefreshPlatformFlags = [
	'platform-component-visual-refresh',
	'platform-visual-refresh-icons-legacy-facade',
	'platform.design-system-team.enable-new-icons',
];

export const initPlatformFeatureFlags = () => {
	if (ff('fix_jira_ssr_platform_ff_oxim4')) {
		setBooleanFeatureFlagResolver((flagKey) => {
			if (visualRefreshPlatformFlags.includes(flagKey)) {
				return isVisualRefreshEnabled();
			}
			return fg(flagKey) || ff(flagKey);
		});
	}
};
