import {
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES_FORGE_GLOBAL,
	ROUTE_NAMES_MARKETPLACE_INDEX,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { useRouter, type RouterContext, type RouterDataContext } from '@atlassian/jira-router';

const GENERAL_CONNECT_ROUTES = new Set<string>([
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
]);

const UNCERTAIN_APPS_SECTION_ROUTES = GENERAL_CONNECT_ROUTES;
const CERTAIN_APPS_SECTION_ROUTES = new Set<string>([
	ROUTE_NAMES_FORGE_GLOBAL,
	ROUTE_NAMES_MARKETPLACE_INDEX,
]);

export const APPS_SECTION_ROUTES = new Set<string>([
	...UNCERTAIN_APPS_SECTION_ROUTES,
	...CERTAIN_APPS_SECTION_ROUTES,
]);

/**
 * @returns true when the route is one of many that maybe be rendered under the "Apps" section.
 * May return false positive results in case the current route is a general Connect route.
 */
export function isAppsRoute(routerContext: RouterContext | RouterDataContext) {
	const { route } = routerContext;
	return APPS_SECTION_ROUTES.has(route.name);
}

/**
 * @returns true when the route is one of many that maybe be rendered under the "Apps" section.
 * May return false positive results in case the current route is a general Connect route.
 */
export function useIsAppsRoute() {
	const [routerContext] = useRouter();
	return isAppsRoute(routerContext);
}

/**
 * @returns true when the current route is a general Connect route.
 */
export function useIsGeneralConnectRoute() {
	const [routerContext] = useRouter();
	return GENERAL_CONNECT_ROUTES.has(routerContext.route.name);
}
