// eslint-disable-next-line jira/import-whitelist
import {
	getTeamsResource as platformGetTeamsResource,
	getTeams,
} from '@atlassian/eoc-overview/resources';
import { createResource } from '@atlassian/jira-router';

export const getTeamsResource = createResource({
	type: platformGetTeamsResource.type,
	getKey: platformGetTeamsResource.getKey,
	getData: async (_, { tenantContext: { atlassianAccountId, isSiteAdmin, isAdmin } }) => {
		if (!atlassianAccountId) {
			return null;
		}

		const shouldSendAccountId = !isSiteAdmin || !isAdmin;

		return getTeams({
			queryParams: {
				accountId: shouldSendAccountId ? atlassianAccountId : undefined,
				showAvatar: true,
			},
		});
	},
	isBrowserOnly: true,
	maxAge: 0,
});
