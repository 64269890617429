import { fg } from '@atlassian/jira-feature-gating';

const DUMMY_CONDITION = true;

// ref: similar implementation with
// src/packages/navigation-apps/sidebar-nav4/rollout/src/common/utils/get-will-show-nav4/get-will-show-nav4-for-eap/index.tsx
// to accomondate the complex rollout logic
// have to use a DUMMY_CONDITION to not trigger build error
// the condition will be replaced later with logics related to nav4
export const isVisualRefreshEnabled = () => fg('visual-refresh') && DUMMY_CONDITION;
